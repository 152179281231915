import React, { useState, useEffect, useRef } from "react";
import ProgressBar from "../Common/ProgressBar/ProgressBar.jsx"; 
import "./FileTransfer.scss";
import logo from '../../img/avionica.png';

const FileTransfer = () => {

  const [searchTerm, setSearchTerm] = useState("");
  const [files, setFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [progress, setFetchProgress] = useState(0);
  const [uploadProgress, setUploadProgress] = useState(0);
  let wsRef = useRef(null);

  useEffect(() => {
    const ws = new WebSocket("wss://fdl-service.azurewebsites.net");
    ws.onopen = () => {
      console.log("WebSocket connection established");
    };

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.type === "uploadProgress") {
        setUploadProgress(data.percentComplete);
        if (data.percentComplete === "100.00") {
          fetchFiles();
        }
      }
    };

    ws.onclose = () => {
      console.log("WebSocket connection closed");
    };

    ws.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    return () => ws.close();
  }, []);

  useEffect(() => {
    fetchFiles();
  }, []);

  const fetchFiles = () => {
    fetch("https://fdl-service.azurewebsites.net/downloads")
      .then(response => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then(data => {
        setFiles(data);
      })
      .catch(error => console.error("Error fetching files:", error));
  };

  const handleFileChange = (event) => { 
    setSelectedFile(null);
    setUploadProgress(0);
    setFetchProgress(0);
    const file = event.target.files[0]; 
    if (file) {

        setSelectedFile(file);
        console.log("Selected file:", file.name);
        chunkRead(file);
    }
};

const chunkRead = (file) => {
  const chunkSize = 4096; 
  let offset = 0;

  const readNext = () => {
      const slice = file.slice(offset, offset + chunkSize);
      const reader = new FileReader();

      reader.onload = () => {
          offset += chunkSize;
          const percent = Math.min(100, Math.floor((offset / file.size) *1000));
          setFetchProgress(percent);

          if (offset < file.size){
              readNext();
          } else {
              console.log("Finished loading");
              uploadFile(file);
          }
      };

      reader.onerror = () => {
          console.error("Error reading file");
      };
      reader.readAsArrayBuffer(slice);
  };
  readNext();
};


  const handleFileSelect = () => {
    alert("Allow up to a minute for file to load once selected from iPad, will seem 'stuck' due to iOS");
    
    if (window.hiddenFileInput) {
        window.hiddenFileInput.click();
    }
};

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };


  const downloadFile = (filename) => {
    const url = `https://fdl-service.azurewebsites.net/download/${encodeURIComponent(
      filename
    )}`;
    window.location.href = url;
  };


  const uploadFile = (file) => {
    const formData = new FormData();
    formData.append("file", file);

    
      fetch('https://fdl-service.azurewebsites.net/upload',{
        method: "POST",
        body: formData,
      }).then((response) => {
        if(!response.ok) throw new Error("Upload failed");
        return response.text();
      }).then((result) => {
        console.log("Succesful upload", result);
        fetchFiles();
      }). catch((error) =>{
      console.error("Upload failed", error)
    });
  }

  const obtainTailName = (filename) => {
    const index = filename.indexOf('_');
    if (index === -1) return '-';
    return filename.substring(0, index);
  }

  return (
    <div>
      <div className="container">
        <img className="avionica-logo" src={logo} alt="Logo" />
        <h1>avCloudConnect</h1>
        <div className="card">
          <div className="card-header text-left bg-transparent">
            <h1>FDR Downloader</h1>
          </div>
          <div className="card-body">
            <div className="card-buttons">
              <button className="btn btn-warning" onClick={handleFileSelect}>Select File</button>
              <input
                type="file"
                accept="*/*"
                ref={(ref) => (window.hiddenFileInput = ref)}
                style={{ display: 'none' }}
                onChange={handleFileChange}
            />
            </div>
              {selectedFile && (
                <div style={{ marginTop: '20px' }}>
                  <p>Selected Files: {selectedFile.name}</p>
                  <p>Size: {(selectedFile.size / 1024).toFixed(2)} KB</p>

                  <ProgressBar barHeight={20} progressPercentage={progress} />
                  <p>Pocket Reader Progress: {progress}%</p>
                  <ProgressBar barHeight={20} progressPercentage={uploadProgress} />
                  <p>Upload Progress: {uploadProgress}%</p>
                </div>
              )}
          </div>
        </div>
      </div>
      <div className="file-container">
        <h3>Uploaded files</h3>
        <input
          type="text"
          placeholder="Search files..."
          onChange={handleSearchChange}
          className="search-file"
        />
        <div className="table-card">
          <table>
            <thead>
              <tr>
                <th>File</th>
                <th>Upload Date</th>
                <th>Tail Number</th>
                <th>Download Section</th>
              </tr>
            </thead>
            <tbody>
              {files
                .filter((file) =>
                  file.name.toLowerCase().includes(searchTerm.toLowerCase())
                )
                .sort((a, b) => new Date(b.lastModified) - new Date(a.lastModified))
                .map((file) => (
                  <tr key={file.lastModified}>
                    <td>{file.name}</td>
                    <td>{file.lastModified}</td>
                    <td>{obtainTailName(file.name)}</td>
                    <td>
                      <button className="btn btn-warning" onClick={() => downloadFile(file.name)}>
                        Download File
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="space-files"></div>
    </div>
  );
};

export default FileTransfer;
