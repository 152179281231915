import React, { useState } from 'react';
import './login.scss'

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const validateCredentials = () => {
    const hardcodedUsername = 'PSAbetatest';
    const hardcodedPassword = '7gC!9dP$qx!4@L2';
    let isValid = true;

    if (username.toLocaleLowerCase() !== hardcodedUsername.toLocaleLowerCase()) {
      setUsernameError('Incorrect username');
      isValid = false;
    } else {
      setUsernameError('');
    }

    if (password !== hardcodedPassword) {
      setPasswordError('Incorrect password');
      isValid = false;
    } else {
      setPasswordError('');
    }

    return isValid;
  };

  const handleLogin = (event) => {
    event.preventDefault(); // Prevent form submit from reloading the page
    if (validateCredentials()) {
      onLogin(true);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleLogin(event);
    }
  };

  return (
    <div className="login-container">
      <input
        type="text"
        placeholder="Username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        onKeyPress={handleKeyPress}
        className={usernameError ? 'input-error' : ''}
      />
      {usernameError && <p className="error-message">{usernameError}</p>}
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        onKeyPress={handleKeyPress}
        className={passwordError ? 'input-error' : ''}
      />
      {passwordError && <p className="error-message">{passwordError}</p>}
      <button onClick={handleLogin}>Login</button>
    </div>
  );
};

export default Login;
